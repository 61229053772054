<template>
  <div class="lawArticles-list-view">
    <div class="child-page-title">
      <h3>{{ title }}</h3>
    </div>
    <div v-if="hasVal(config)" class="lawArticles-list-context">
      <dv-scroll-board :config="config" class="dv-scroll-board" />
    </div>
    <div v-else class="stat-charts-context empty">暂无{{ title }}</div>
  </div>
</template>
<script>
import { getSimpleArticleList } from '@/api/lawArticles'
import { hasVal, parseTime } from '@/utils/index'
export default {
  name: 'LawArticlesList',
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '政策资讯'
    }
  },
  data() {
    return {
      top: 50,
      config: null,
      columnWidth: [59, 380, 80],
      aligns: ['center', 'left', 'center']
    }
  },
  mounted() {
    this.removeLawArticlesList()
  },
  methods: {
    removeLawArticlesList() {
      getSimpleArticleList(this.type, this.top).then((res) => {
        const { data } = res
        const _data = []
        let _itemData = []
        if (hasVal(data.items)) {
          data.items.forEach((item) => {
            _itemData = []
            _itemData.push(item.title)
            _itemData.push(item.timeSpan)
            _data.push(_itemData)
          })
          this.config = {
            columnWidth: this.columnWidth,
            index: true,
            align: this.aligns,
            data: _data,
            rowNum: 18,
            evenRowBGC: '',
            oddRowBGC: 'rgba(0,255,255,.2)'
          }
        }
      })
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.lawArticles-list-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .lawArticles-list-context {
    margin-top: 5px;
    .dv-scroll-board {
      height: 880px;
    }
  }
}
</style>
