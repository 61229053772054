<template>
  <div
    class="x-bar"
    :style="`height: ${height}px; margin-top: ${marginTop}px;`"
  >
    <div :id="id" :option="option" :style="`height: ${height}px;`" />
  </div>
</template>
<script>
import Highcharts from 'highcharts/highstock'
import HighchartsMore from 'highcharts/highcharts-more'
import { hasVal } from '@/utils/index'
HighchartsMore(Highcharts)
export default {
  name: 'AreaStat',
  props: {
    height: {
      type: Number,
      default: 250
    },
    marginTop: {
      type: Number,
      default: 8
    },
    id: {
      type: String
    },
    valOptions: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: '面积图'
    },
    yAxisTitle: {
      type: String,
      default: '面积图'
    },
    unit: {
      type: String,
      default: '人'
    }
  },
  data() {
    return {
      option: null,
      colorOptions: ['#00ffff', '#01c2fa', '#1cef22', '#fcd728']
    }
  },
  watch: {
    valOptions(val) {
      this.load()
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    /** 数据初始化 */
    load() {
      if (!hasVal(this.valOptions)) return
      const _series = []
      let _data = []
      let _categories = []
      if (hasVal(this.valOptions)) {
        let i = 0
        this.valOptions.forEach((item) => {
          _data = []
          _categories = []
          item.data.forEach((item1) => {
            _data.push(Number(item1.val))
            _categories.push(item1.title)
          })
          _series.push({
            name: item.name,
            data: _data,
            color: this.colorOptions[i]
          })
          if (i == this.colorOptions.length - 1) i = 0
          else i++
        })
      }
      this.showArea(_series, _categories)
    },
    showArea(_series, _categories) {
      this.option = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          backgroundColor: '',
          type: 'area'
        },
        title: {
          text: this.title
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}' + this.unit + '</b>'
        },
        xAxis: {
          categories: _categories,
          labels: {
            style: {
              color: '#eee'
            }
          }
        },
        yAxis: {
          title: {
            text: this.yAxisTitle,
            style: {
              fontSize: '14px',
              fontFamily: 'Microsoft YaHei',
              color: '#eee'
            }
          },
          labels: {
            style: {
              color: '#eee'
            }
          },
          gridLineColor: 'rgba(255,255,255,.5)'
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          borderWidth: 0,
          itemStyle: {
            fontSize: '14px',
            fontFamily: 'Microsoft YaHei',
            fontWeight: 300,
            lineHeight: '18px',
            color: '#eee'
          }
        },
        plotOptions: {
          line: {
            dataLabels: {
              // 开启数据标签
              enabled: true
            }
          }
        },
        credits: {
          enabled: false
        },
        series: _series
      }
      Highcharts.chart(this.id, this.option)
    }
  }
}
</script>
