<template>
  <div class="personnel-rank-list-view">
    <div class="child-page-title">
      <h3>{{ title }}</h3>
    </div>
    <div v-if="hasVal(config)" class="personnel-rank-list-context">
      <dv-scroll-board :config="config" :style="'height:' + height + 'px'" />
    </div>
    <div v-else class="stat-charts-context empty">暂无{{ title }}信息</div>
  </div>
</template>
<script>
import { hasVal } from '@/utils/index'
export default {
  name: 'PersonnelRankList',
  props: {
    title: {
      type: String,
      default: '人员红黑榜列表'
    },
    valOptions: {
      type: Array,
      default: null
    },
    rowNum: {
      type: Number,
      default: 3
    },
    height: {
      type: Number,
      default: 128
    }
  },
  data() {
    return {
      config: null,
      columnWidth: [50, 255.9, 100],
      aligns: ['center', 'left', 'center'],
      defaultAvatar: require('../../../assets/image/tab_icon_mine_default.png')
    }
  },
  watch: {
    valOptions(val) {
      this.initData()
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      if (hasVal(this.valOptions)) {
        const _data = []
        let _itemData = []
        this.valOptions.forEach((item) => {
          _itemData = []
          _itemData.push(
            "<img src='" +
              (hasVal(item.mugshot) ? item.mugshot : this.defaultAvatar) +
              "' />"
          )
          _itemData.push(
            item.name + '(' + item.corpName + '/' + item.teamName + ')'
          )
          _itemData.push('上榜：' + item.count + '次')
          //   _itemData.push(item.timeStr);
          _data.push(_itemData)
        })
        this.config = {
          columnWidth: this.columnWidth,
          align: this.aligns,
          data: _data,
          rowNum: this.rowNum,
          evenRowBGC: '',
          oddRowBGC: 'rgba(0,255,255,.2)'
        }
      }
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.personnel-rank-list-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .personnel-rank-list-context {
    margin-top: 3px;
  }
}
.empty {
  margin-top: 40px !important;
}
</style>
