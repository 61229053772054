import request from '@/utils/request'

/** *
 * 最新数条资讯信息
 */
export function getSimpleArticleList(type, top) {
  return request({
    url: '/LawArticles/GetSimpleArticleList',
    method: 'GET',
    params: {
      type: type,
      limit: top
    }
  })
}
