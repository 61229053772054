<template>
  <div class="weather-tmp-stat-view">
    <div class="child-page-title">
      <h3>未来{{ days }}天{{ cityName }}最高气温趋势</h3>
    </div>
    <div class="stat-context">
      <area-chart
        id="weather-tmp-area"
        :val-options="dailyForecast"
        :height="380"
        title=""
        unit="℃"
        y-axis-title="最高气温趋势(℃)"
        serie-name="气温"
      />
    </div>
  </div>
</template>
<script>
import { getWeather } from '@/api/weather'
import { hasVal } from '@/utils/index'
import areaChart from '@/components/chart/areaChart.vue'
export default {
  name: 'WeatherView',
  components: { areaChart },
  props: {
    days: {
      type: Number,
      default: 7
    }
  },
  data() {
    return {
      cityName: null,
      dailyForecast: []
    }
  },
  mounted() {
    this.removeWather()
  },
  methods: {
    /**
     * 加载项目天气数据
     */
    removeWather() {
      getWeather().then((res) => {
        const { result } = res
        const _heWeather5 = result.HeWeather5
        if (Array.isArray(_heWeather5) && _heWeather5.length > 0) {
          const item = _heWeather5[0] || {}
          this.cityName = item.basic.city
          if (hasVal(item.daily_forecast)) {
            const tmpOptions = []
            for (let i = 0; i < this.days; i++) {
              tmpOptions.push({
                title: item.daily_forecast[i].date,
                val: item.daily_forecast[i].tmp.max
              })
            }
            this.dailyForecast.push({
              name: this.cityName,
              data: tmpOptions
            })
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.weather-tmp-stat-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-context {
    width: 99%;
    padding-left: 0.5%;
  }
}
</style>
