<template>
  <!-- 方块组件 -->
  <div class="drawer-box-block">
    <!-- 左侧方块 -->
    <div class="drawer-box-left">
      <!-- 左侧方块 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-left-all"
      >
        <slot name="leftDrawerContent" />
      </dv-border-box-7>
    </div>
    <!--中间方块-->
    <div class="drawer-box-middle">
      <!-- 中间上半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-middle-top"
      >
        <slot name="MiddleDrawerTopContent" />
      </dv-border-box-7>
      <!--中间方块-->
      <div class="drawer-box-middle-middle">
        <!-- 中间左部分 -->
        <dv-border-box-7
          :color="['#10568e', 'rgb(124, 231, 253)']"
          class="drawer-box-middle-middle-left"
        >
          <slot name="MiddleDrawerLeftMiddleContent" />
        </dv-border-box-7>
        <!-- 中间右部分 -->
        <dv-border-box-7
          :color="['#10568e', 'rgb(124, 231, 253)']"
          class="drawer-box-middle-middle-right"
        >
          <slot name="MiddleDrawerRightMiddleContent" />
        </dv-border-box-7>
      </div>
      <!-- 中间下部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-middle-bottom"
      >
        <slot name="MiddleDrawerBottomContent" />
      </dv-border-box-7>
    </div>
    <!-- 右侧方块 -->
    <div class="drawer-box-right">
      <!-- 右侧上半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-right-all"
      >
        <slot name="rightDrawerContent" />
      </dv-border-box-7>
    </div>
  </div>
</template>
<script>
export default {
  name: "DrawerLawArticles",
  props: {
    title: {
      type: String,
      default: "公告看板",
    },
  },
};
</script>
<style lang="scss" scoped>
.drawer-box-block {
  // 左侧方块样式
  .drawer-box-left {
    height: 924px;
    width: 519px;
    position: fixed;
    top: 125px;
    z-index: 1;
    opacity: 0.92;
    left: 15px;
    .drawer-box-left-all {
      width: 100%;
      height: 100%;
      border: 0px;
      background: rgba(100, 116, 211, 0.04);
      opacity: 0.8;
    }
  }
  .drawer-box-middle {
    width: 825px;
    height: 924px;
    position: fixed;
    top: 125px;
    z-index: 1;
    opacity: 0.92;
    left: 547px;
    .drawer-box-middle-top {
      width: 100%;
      height: 300px;
      background: rgba(100, 116, 211, 0.04);
      border: 0px;
      opacity: 0.8;
    }
    .drawer-box-middle-middle {
      width: 100%;
      height: 170px;
      margin-top: 12px;
      display: flex;
      .drawer-box-middle-middle-left {
        width: 49.5%;
        background: rgba(100, 116, 211, 0.04);
        border: 0px;
        opacity: 0.8;
      }
      .drawer-box-middle-middle-right {
        width: 49.5%;
        margin-left: 1%;
        background: rgba(100, 116, 211, 0.04);
        border: 0px;
        opacity: 0.8;
      }
    }
    .drawer-box-middle-bottom {
      width: 100%;
      height: 426px;
      margin-top: 12px;
      background: rgba(100, 116, 211, 0.04);
      border: 0px;
      opacity: 0.8;
    }
  }
  // 右侧方块样式
  .drawer-box-right {
    height: 924px;
    width: 519px;
    position: fixed;
    top: 125px;
    z-index: 1;
    opacity: 0.92;
    right: 15px;
    .drawer-box-right-all {
      width: 100%;
      height: 100%;
      background: rgba(100, 116, 211, 0.04);
      border: 0px;
      opacity: 0.8;
    }
  }
}
</style>