<template>
  <div class="home">
    <child-page-head :title="title" />
    <div class="home-top-main" />
    <!-- 方块组件 -->
    <drawer-lawArticles>
      <!-- 左侧内容 -->
      <div slot="leftDrawerContent">
        <drawer-content type="1397827580699500544" title="政策新闻" />
      </div>
      <!-- 中间部分-上 -->
      <div slot="MiddleDrawerTopContent">
        <weather-log-drawer-content />
      </div>
      <!-- 中间部分-中-左 -->
      <div slot="MiddleDrawerLeftMiddleContent">
        <middle-drawer-middle-content title="红榜" :val-options="redList" />
      </div>
      <!-- 中间部分-中-左 -->
      <div slot="MiddleDrawerRightMiddleContent">
        <middle-drawer-middle-content title="黑榜" :val-options="blackList" />
      </div>
      <!-- 中间部分-下 -->
      <div slot="MiddleDrawerBottomContent">
        <weather-tmp-drawer-content />
      </div>
      <!-- 右侧内容-->
      <div slot="rightDrawerContent">
        <drawer-content type="1397827580699500545" title="建筑安全" />
      </div>
    </drawer-lawArticles>
  </div>
</template>
<script>
import { getPersonnelRank } from '@/api/personnel'
import childPageHead from '@/components/child-page-head'
import drawerLawArticles from '@/components/drawer-lawArticles'
import weatherLogDrawerContent from '@/views/common/weather-log-drawer-content.vue'
import weatherTmpDrawerContent from '@/views/common/weather-tmp-drawer-content.vue'
import drawerContent from './components/drawer-content.vue'
import middleDrawerMiddleContent from './components/middle-drawer-middle-content.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'LawArticlesHome',
  components: {
    drawerLawArticles,
    childPageHead,
    weatherLogDrawerContent,
    weatherTmpDrawerContent,
    drawerContent,
    middleDrawerMiddleContent
  },
  data() {
    return {
      title: '公告看板',
      redList: [],
      blackList: []
    }
  },
  mounted() {
    this.removeLoadPersonnelRank()
  },
  methods: {
    removeLoadPersonnelRank() {
      this.redList = []
      this.blackList = []
      getPersonnelRank().then((res) => {
        const { data } = res
        this.redList = hasVal(data.redList) ? data.redList : []
        this.blackList = hasVal(data.blackList) ? data.blackList : []
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  background: url(../../assets/image/body-bg.jpg) no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  zoom: 1;
  &-top-main {
    padding: 0 13px;
    display: flex;
    justify-content: space-between;
    height: "calc(100vh - 102px)";
  }
}
</style>
